import { Splide } from "@splidejs/splide";
import sal from 'sal.js'
import A11yDialog from 'a11y-dialog'

import { MobileMenu } from "./mobile-menu";

class App {
    primaryNav;
    mobileNav;

    constructor() {
        sal({
            threshold: 0.25,
            once: true,
        });

        this.mobileMenu = new MobileMenu();

        const carousels = document.querySelectorAll('.simple-splide-carousel');
        carousels.forEach(carousel => {
            new Splide(carousel, {
                pagination: false,
                type: 'fade',
            }).mount();
        })

        if (document.querySelector('#blog-posts-carousel')) {
            new Splide('#blog-posts-carousel', {
                pagination: false,
                arrows: false,
                mediaQuery: 'min',
                breakpoints: {
                    1024: {
                        destroy: true,
                    },
                },
                padding: {
                    left: '2rem',
                    right: '4rem',
                },
                gap: '2rem',
            }).mount();
        }

        const dialogTriggers = document.querySelectorAll('[data-a11y-dialog-show]');
        dialogTriggers.forEach(dialogTrigger => {
            const dialogId = dialogTrigger.getAttribute('data-a11y-dialog-show');
            const dialog = new A11yDialog(document.getElementById(dialogId));

            const iframe = dialog.$el.querySelector('iframe');
            if (iframe) {
                dialog.$el.addEventListener('show', () => {
                    iframe.src = iframe.dataset.src;
                });

                dialog.$el.addEventListener('hide', () => {
                    iframe.src = null;
                });
            }
        });
    }
}

const app = new App();