export class MobileMenu {

    mobileMenu;
    openMobileMenuBtn;
    closeMobileMenuBtn;

    constructor () {
        this.mobileMenu = document.querySelector('#mobileMenu');
        this.openMobileMenuBtn = document.querySelector('#openMobileMenuBtn');
        this.closeMobileMenuBtn = document.querySelector('#closeMobileMenuBtn');

        openMobileMenuBtn.addEventListener('click', () => {
            mobileMenu.classList.remove('opacity-0', 'pointer-events-none');
            mobileMenu.classList.add('opacity-100', 'pointer-events-auto');
        });

        closeMobileMenuBtn.addEventListener('click', () => {
            mobileMenu.classList.remove('opacity-100', 'pointer-events-auto');
            mobileMenu.classList.add('opacity-0', 'pointer-events-none');
        });

        const subMenusBtns = mobileMenu.querySelectorAll('.subMenuCollapseBtn');
        subMenusBtns.forEach(subMenuBtn => {
            subMenuBtn.addEventListener('click', this.toggleSubMenu.bind(this));
        });
    }

    toggleSubMenu(e) {
        const subMenuBtn = e.currentTarget;
        const expanded = subMenuBtn.getAttribute('aria-expanded') === 'true';
        expanded ? this.closeSubMenu(subMenuBtn) : this.openSubMenu(subMenuBtn)
    }

    openSubMenu(subMenu) {
        const target = subMenu.getAttribute('aria-controls');
        subMenu.setAttribute('aria-expanded', true);
        subMenu.querySelector('svg').classList.add('rotate-180');
        mobileMenu.querySelector(target).style.display = 'block';
    }

    closeSubMenu(subMenu) {
        const target = subMenu.getAttribute('aria-controls');
        subMenu.setAttribute('aria-expanded', false);
        subMenu.querySelector('svg').classList.remove('rotate-180');
        mobileMenu.querySelector(target).style.display = 'none';
    }
}